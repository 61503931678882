<template>
    <div id="text">
        <div class="newCategory" v-loading="loading" >
            <el-breadcrumb separator="/" class="path">
                <el-breadcrumb-item :to="{ path: '/' }" class="path-home">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/cms/help' }" class="path-help" >产品中心</el-breadcrumb-item>
            </el-breadcrumb> 

            <div class="help"  style="border: 1px solid #f1f1ff;padding-bottom: 0px;display:block;margin-top:10px;margin-bottom:50px;">
                <div style="display:flex;width: 100%;">
                    <div style="font-size:16px;font-weight:bold;width:20%;text-align:center;line-height:50px;">产品分类</div>
                    <div  class="menu" style="height: 50px;width: 80%;border:none;min-height:40px;display:flex;align-items: center;font-size:16px;justify-content: space-around;">
                        
                        <div class="hoversss"  v-for="(item, index) in goodslist" :key="index" :class="item.flag?'item-flag':''" @click="categoryTopItemClick(item.category_id,item.category_name)">{{item.category_name}}</div>
                        <!-- <div class="title">帮助列表</div>
                        <div class="item" v-for="(item, index) in helpList" :key="index">
                        <div
                            :class="currentId == item.class_id ? 'active item-name' : 'item-name'"
                            @click="menuOther(item.class_id)"
                        >{{ item.class_name }}</div>
                        </div> -->
                    </div>
                </div>
                <div style="display:flex;width: 100%;">
                    <div style="font-size:16px;font-weight:bold;width:20%;text-align:center;line-height:50px;">产品系列</div>
                    <div  class="menu" style="height: 50px;width: 80%;border:none;min-height:40px;display:flex;align-items: center;font-size:16px;justify-content: space-around;">
                        <div class="hoversss" :class="quanbuflag?'item-flag':''"  @click="categoryTopItemClick22()">全部</div>
                        <div class="hoversss" v-for="(item, index) in goodslist2" :key="index" :class="item.flag?'item-flag':''" @click="categoryTopItemClick2(item.category_id)">{{item.category_name}}</div>
                        <!-- <div class="title">帮助列表</div>
                        <div class="item" v-for="(item, index) in helpList" :key="index">
                        <div
                            :class="currentId == item.class_id ? 'active item-name' : 'item-name'"
                            @click="menuOther(item.class_id)"
                        >{{ item.class_name }}</div>
                        </div> -->
                    </div>
                </div>
                
                <div class="list-other">
                    <transition name="slide">
                    <router-view />
                    </transition>
                </div>
            </div>
            <div v-show="ProductList">
                <div style="width:1920px;display:flex;">
                    <p style="color:#333333; font-size:24px;">产品展示</p>
                    <span style="color:#E40916; font-size:24px;margin-left:15px;">PRODUCT DISPLAY</span>
                </div>
                <div style="display:flex;justify-content: space-between;flex-wrap: wrap;margin-bottom:60px;">
                    <div style="height:367px; width:292.5px;background:#fff;margin-top:20px;" v-for="(item, index) in goodslist3" :key="index" >
                        <img style="" :src="item.image" alt="" @click="showOrno(item.id)" class="ProductListImg" >
                        <p style="margin-top:30px;text-align:center;font-size:24px;">{{item.name}}</p>
                    </div>
                </div>
            </div>
            <div v-show="ProductListDtail" v-for="(item, index) in ProductListDtailList" :key="index" >
                <el-divider></el-divider>  
                <div style="display:flex">
                    <div style="height:582px;width:482px;border: solid 1px #DDDDDD;padding:10px;">
                        <img :src="item.goods_image" alt=""   style="height: 100%;"  >
                    </div>
                    <div style="margin-left:60px;margin-bottom:90px;">
                        <p style="color:#333333;font-size:24px;margin-top:50px;">产品名称:{{item.goods_name}}</p>
                        <p style="width:500px;color:#666666; margin-top:40px;font-size:14px;">{{item.introduction}}</p>
                        <el-divider></el-divider>
                        <p style="margin-top:60px;font-size:14px;color:#666666;">产品分类:{{ProductListDtailListName}}</p>  
                        <div style="color:#666666; display:flex;" >
                            <p>产品颜色:</p> 
                            <div v-for="(item1, index1) in item.color" :key="index1" :class="sssflag==item1 ? 'item-flag':'' " @click="colorSelect(item1,item.goods_id)" style="border: 1px solid red;border-radius: 25px;margin-left:5px;padding:0 2px;cursor: pointer;">{{item1}}</div>
                        </div>
                        <div v-for="(item2, index2) in priceAndColor" :key="index2"   style="color:#666666; " >
                            <p>{{item2.spec_name}}</p> 
                            <p>{{item2.price}}</p>
                        </div>
                        <div style="margin-top:90px;width:200px;height:200px;background:wihte;border: solid 1px #DDDDDD;"><img :src="item.erweima" style="width:100%;" alt=""></div>      
                    </div>
                </div>
                <div style="display:flex;margin-bottom:40px;">
                    <p style="color:#333333; font-size:24px;">产品详情</p>
                    <span style="color:#E40916; font-size:24px;margin-left:15px;">PRODUCT DETAILS</span>
                </div>
                <div style="text-align:center;" v-html="item.goods_content"></div>
                <div style="display:flex;margin-bottom:40px;">
                    <p style="color:#333333; font-size:24px;">产品推荐</p>
                    <span style="color:#E40916; font-size:24px;margin-left:15px;">RECOMMENDATION</span>
                </div>
                <div style="display:flex;flex-wrap:wrap;justify-content: space-between;flex-direction: revert;margin-bottom:20px" id="text">
                        <div @click="showOrno(item.id)" class="recommend" style="height: 367px;width: 282.5px;border:1px solid #f9f9f9;text-align:center;padding-top:10px;" v-for="(item, index) in recommend" :key="index">
                            
                            <a href="#text"><img :src="item.image" alt="" style="width:95%;height:80%;"></a> 
                            <p style="text-align:center;">{{item.name}}</p>
                        </div>
                </div>
            </div>

            <!-- <div class="categoryLink">
                <ul id="categoryUl" :class="categoryFixed == true ? 'category-fixed' : ''">
                    <li v-for="(item, index) in goodsCategory" :key="index" :class="index == clickIndex ? 'selected' : ''" @click="changeCate(index, '#category' + index)">
                        <a>
                            <span>{{ item.category_name }}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="categoryCon">
                <div :id="'category' + index" :ref="'category' + index" class="items" :class="'items-' + index" v-for="(cate1, index) in goodsCategory" :key="index">
                    <h2>
                        <router-link :to="{ path: '/list', query: { category_id: cate1.category_id, level: cate1.level } }" target="_blank">{{ cate1.category_name }}</router-link>
                    </h2>

                    <dl v-for="(cate2, index) in cate1.child_list" :key="index">
                        <dt>
                            <router-link :to="{ path: '/list', query: { category_id: cate2.category_id, level: cate2.level } }" target="_blank">
                                {{ cate2.category_name }}
                            </router-link>
                        </dt>
                        <dd>
                            <router-link v-for="(cate3, index) in cate2.child_list" :key="index" :to="{ path: '/list', query: { category_id: cate3.category_id, level: cate3.level } }" target="_blank">
                                {{ cate3.category_name }}
                            </router-link>
                        </dd>
                    </dl>
                </div>
            </div>
			
			<div class="empty-wrap" v-if="goodsCategory.length <= 0"><div class="ns-text-align">暂无商品分类</div></div> -->
        </div>
    </div>
</template>

<script>
    import { tree } from "@/api/goods/goodscategory"
    import Config from "@/utils/config.js"
    export default {
        name: "category",
        components: {},
        data: () => {
            return {
                recommend:[],
                ProductListDtailListName:"",   
                ProductListDtailList:[],
                ProductListDtail:false,
                ProductList:true,
                goodslist3:[],
                goodslist2:[],
                goodslist:[],
                goodsCategory: [],
                categoryFixed: false,
                clickIndex: 0,
                loading: true,
                tree1id:0,
                tree2id:0,
                detailImg:0,
                priceAndColor:[],
                sssflag:"",
                daoer2:0,
                quanbuflag:false
            }
        },
        created() {
            


            console.log("fgjjgh",Config.baseUrl+`api/Goodscategory/tree2`)
            this.getGoodsCategory()
            
           
            



            //导一
            var _this=this;
            _this.$ajax.post(
                Config.baseUrl+`api/Goodscategory/tree1`,
                // _this.$qs.parse({
                // id:"32"
                // })
            )
            .then(e=> {
                //导一提出默认id
                _this.goodslist=e.data
                _this.tree1id=e.data[0].category_id
                _this.daoer2=e.data[0].category_id
                // console.log("11111111111111111111",e.data[0].category_id)
                _this.ProductListDtailListName=e.data[0].category_name
                //导二
                _this.$ajax.post(
                    Config.baseUrl+`api/Goodscategory/tree2`,
                    _this.$qs.parse({
                    id:_this.tree1id
                    })
                )
                .then(e=> {
                    // let data=res.data.data;
                    _this.goodslist2=e.data
                    _this.tree2id=e.data[0].category_id

                    //导三
                    _this.$ajax.post(
                        Config.baseUrl+`api/Goodscategory/tree3`,
                        _this.$qs.parse({
                        id:_this.tree2id
                        })
                    )
                    .then(e=> {
                        // let data=res.data.data;
                        // console.log("3333333333333333333",e)
                        _this.goodslist3=e.data
                        // _this.goodslist2=e.data
                        // console.log("33333333333333sdasdsadasd 33333",e)
                        _this.categoryTopItemClick22()
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                })
                .catch(error=>{
                    console.log(error)
                })


            })
            .catch(error=>{
                console.log(error)
            })



            _this.$ajax.post(
                Config.baseUrl+`api/Goodscategory/tree5`,
                _this.$qs.parse({
                // id:ItemId
                })
            )
            .then(e=> {
                // let data=res.data.data;
                // _this.goodslist2=e.data
                 _this.recommend=e.data
                console.log("555555555555555555",e)
                // _this.tree2id=e.data[0].category_id
            })
            .catch(error=>{
                console.log(error)
            })
            
            if(this.$route.query.id){
                setTimeout(()=>{   //设置延迟执行
                    _this.showOrno(this.$route.query.id)
                },500);
            }
            
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll)
            
        },
        methods: {
            colorSelect(item,goodID){
                
                var _this=this;
                _this.sssflag = item
                // console.log("sssssssss",item,goodID)
                //导三
                _this.$ajax.post(
                    Config.baseUrl+`api/Goodscategory/tree6`,
                    _this.$qs.parse({
                    id:goodID,
                    color:item
                    })
                )
                .then(e=> {
                    // console.log("----------------1",e)
                    _this.priceAndColor=e.data
                })
                .catch(error=>{
                    console.log(error)
                })
            },
            showOrno(ItemId){
               console.log("fdgdffffffffffffffffff")     







                // console.log("fuckyoutumaike",ItemId)
                this.ProductList=false
                this.ProductListDtail=true
                //导三
                var _this=this;
                _this.$ajax.post(
                    Config.baseUrl+`api/Goodscategory/tree4`,
                    _this.$qs.parse({
                    id:ItemId
                    })
                )
                .then(e=> {
                    _this.ProductListDtailList=e.data
                    _this.sssflag=e.data[0].color[0]
                    _this.priceAndColor=e.data[0].price
                    // let data=res.data.data;
                    // console.log("3333333333333333333",_this.priceAndColor)
                    // _this.goodslist2=e.data

                    
                    // _this.tree2id=e.data[0].category_id
                })
                .catch(error=>{
                    console.log(error)
                })
                //  let top = document.documentElement.scrollTop || document.body.scrollTop;
                // //实现滚动效果
                // const timeTop = setInterval(() => {
                //     document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
                //     if (top <= 0) {
                //     clearInterval(timeTop);
                //     }
                // }, 10);



                //导三
                _this.$ajax.post(
                    Config.baseUrl+`api/Goodscategory/tree6`,
                    _this.$qs.parse({
                    id:ItemId
                    })
                )
                .then(e=> {
                   console.log("-eeeeeeeeeeeeeeeeeeeee",e)
                })
                .catch(error=>{
                    console.log(error)
                }) 
            },


            //导二11111点击
            categoryTopItemClick22(){
                this.quanbuflag=true

                
                var _this=this;
                _this.goodslist2.forEach((item,index,arr)=>{
                    item.flag = false
                })    
                //导三
                _this.$ajax.post(
                    Config.baseUrl+`api/Goodscategory/tree3`,
                    _this.$qs.parse({
                    id:_this.daoer2
                    })
                )
                .then(e=> {
                    // let data=res.data.data;
                    // console.log("3333333333333333333",e)
                    // _this.goodslist2=e.data
                    console.log("3333333333333333333",e)
                    // _this.tree2id=e.data[0].category_id
                    _this.goodslist3=e.data
                })
                .catch(error=>{
                    console.log(error)
                })
                _this.ProductList=true
                _this.ProductListDtail=false
            },



            //导二点击
            categoryTopItemClick2(itemID){
                this.quanbuflag=false
                var _this=this;
                _this.goodslist2.forEach((item,index,arr)=>{
                    item.flag = false
                    if(itemID == item.category_id){
                        item.flag = true
                        // that.topId=item.flag
                        _this.tree2id=itemID
                        // _this.category_id=itemID
                        // uni.setStorageSync("fid",item.id)
                    }
                    _this[index] = item
                })

                //导三
                _this.$ajax.post(
                    Config.baseUrl+`api/Goodscategory/tree3`,
                    _this.$qs.parse({
                    id:_this.tree2id
                    })
                )
                .then(e=> {
                    // let data=res.data.data;
                    // console.log("3333333333333333333",e)
                    // _this.goodslist2=e.data
                    console.log("3333333333333333333",e)
                    // _this.tree2id=e.data[0].category_id
                    _this.goodslist3=e.data
                })
                .catch(error=>{
                    console.log(error)
                })
                _this.ProductList=true
                _this.ProductListDtail=false
            },









            categoryTopItemClick(itemID,itemName){
                this.daoer2=itemID
                var _this=this;
                _this.goodslist.forEach((item,index,arr)=>{
                    item.flag = false
                    if(itemID == item.category_id){
                        item.flag = true
                        _this.ProductListDtailListName=itemName
                        _this.tree1id=itemID
                        // that.topId=item.flag
                        // _this.category_id=itemID
                        // uni.setStorageSync("fid",item.id)
                    }
                    _this[index] = item
                })

                //导二
                var _this=this;
                _this.$ajax.post(
                    Config.baseUrl+`api/Goodscategory/tree2`,
                    _this.$qs.parse({
                    id:_this.tree1id
                    })
                )
                .then(e=> {
                    // let data=res.data.data;
                    _this.goodslist2=e.data
                    // _this.tree1id=e.data[0].category_id
                    // console.log("222222222222222222222222", e.data[0].category_id)
                    _this.tree2id=e.data[0].category_id

                    //导三
                    _this.$ajax.post(
                        Config.baseUrl+`api/Goodscategory/tree3`,
                        _this.$qs.parse({
                        id:_this.tree2id
                        })
                    )
                    .then(e=> {
                        // let data=res.data.data;
                        // console.log("3333333333333333333",e)
                        _this.goodslist3=e.data
                        // _this.goodslist2=e.data
                        // console.log("3333333333333333333",e)
                        _this.categoryTopItemClick22()
                    })
                    .catch(error=>{
                        console.log(error)
                    })

                })
                .catch(error=>{
                    console.log(error)
                })

                
                _this.ProductList=true
                _this.ProductListDtail=false
            },
            // 分类列表
            getGoodsCategory() {
                tree({
                    level: 3,
                    template: 2
                })
                    .then(res => {
                        if (res.code == 0) {
                            this.goodsCategory = res.data
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                        this.loading = false
                    })
            },
            // 监听滚动条
            // handleScroll() {
            //     var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            //     var offsetTop = document.querySelector(".newCategory").offsetTop

            //     if (scrollTop > offsetTop) {
            //         this.categoryFixed = true
            //     } else {
            //         this.categoryFixed = false
            //     }

            //     var divTopArr = []
            //     for (let i = 0; i < this.goodsCategory.length; i++) {
            //         var _top = this.$refs["category" + i][0].offsetTop

            //         divTopArr.push(_top)
            //         var _offset = scrollTop - offsetTop
            //         if (_offset < divTopArr[divTopArr.length - 1]) {
            //             if (_offset >= divTopArr[i] && _offset < divTopArr[i + 1]) {
            //                 this.clickIndex = i
            //             }
            //         } else {
            //             this.clickIndex = divTopArr.length - 1
            //         }
            //     }
            // },
            // 点击左侧分类
            changeCate(index, obj) {
                this.clickIndex = index
                document.querySelector(obj).scrollIntoView(true)
            }
        },
        destroyed() {
            // 离开该页面需要移除这个监听的事件，不然会报错
            window.removeEventListener("scroll", this.handleScroll)
        }
    }
</script>
<style lang="scss" scoped>
// .recommend{margin-right:20px ;}
.ProductListImg{cursor: pointer;}
.item-flag{color: red;}
.hoversss{cursor: pointer;}


.help {
  display: flex;
  padding-bottom: 20px;
  .menu {
    width: 210px;
    min-height: 300px;
    background: #ffffff;
    border: 1px solid #f1f1ff;
    flex-shrink: 0;
    .title {
      padding-left: 16px;
        background: #f8f8f8;
        font-size: $ns-font-size-base;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        color: #666666;
    }
    .item-name {
      font-size: $ns-font-size-base;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 40px;
      border-top: 1px solid #f1f1f1;
      padding-left: 25px;
      padding-right: 10px;
      height: 40px;
      background: #ffffff;
      color: #666666;
      &:hover {
        color: $base-color;
      }
    }
    .active {
      color: $base-color;
    }
  }
}
















    .newCategory {
        width: $width;
        margin: 0 auto;
        overflow: hidden;
        background-color: #fff;
        margin-top: 10px;
        .categoryLink {
            padding-top: 10px;
            float: left;
            position: relative;
            width: 210px;

            ul {
                width: 210px;
                padding-top: 20px;
				background-color: #FFFFFF;

                li {
                    width: 200px;
                    height: 30px;
                    text-align: left;
                    background-color: #f5f5f5;
                    border-radius: 30px;
                    color: #333;
                    line-height: 30px;
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;
                    margin: 0 auto 15px;

                    &.selected {
                        background-color: $base-color;
                        background: -moz-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                        background: -webkit-gradient(45deg, color-stop(0%, $base-color), color-stop(100%, #ffffff));
                        background: -webkit-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                        background: -o-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                        background: -ms-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                        background: linear-gradient(45deg, $base-color 0%, #ffffff 100%);

                        a {
                            color: #fff;
                        }
                    }

                    a {
                        display: block;
                        margin-left: 30px;
                    }
                }
            }
        }

        .category-fixed {
            position: fixed;
            top: 0;
			z-index: 99;
        }

        .categoryCon {
            float: left;
            padding: 0px 0 60px;
            overflow: hidden;
            width: 990px;
            position: relative;

            .items {
                padding-left: 40px;

                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 40px;
                    margin-top: 30px;
                    border-bottom: 1px solid transparent;
                }

                dl {
                    padding: 15px 0 5px;
                    border-bottom: 1px solid #efefef;
                    overflow: hidden;

                    dt {
                        padding-right: 20px;
                        width: 72px;
                        position: relative;
                        background: #fff;
                        float: left;

                        a {
                            float: left;
                            max-width: 72px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-weight: 600;
                        }
                    }

                    dd {
                        float: left;
                        width: 858px;

                        a {
                            color: #666;
                            float: left;
                            padding: 0 12px;
                            margin: 0 0 10px -1px;
                            border-left: 1px solid #e0e0e0;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
</style>
